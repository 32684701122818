@tailwind base;
@tailwind components;
@tailwind utilities;
/* #481AA1; */
/* #F7D317*/
/* styles.css */

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;

    /* Primary: #481AA1 (Purple) */
    --primary: 267 74% 36%;
    --primary-foreground: 0 0% 98%;

    /* Secondary: #F7D317 (Bright Yellow) */
    --secondary: 49 93% 53%;
    --secondary-foreground: 267 74% 36%;

    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;

    /* Accent using secondary color */
    --accent: 49 93% 53%;
    --accent-foreground: 267 74% 36%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 267 74% 36%;
    --chart-1: 267 74% 36%;
    --chart-2: 49 93% 53%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 267 30% 10%;
    --foreground: 0 0% 98%;
    --card: 267 30% 12%;
    --card-foreground: 0 0% 98%;
    --popover: 267 30% 10%;
    --popover-foreground: 0 0% 98%;

    /* Primary: Lighter version of #481AA1 for dark mode */
    --primary: 267 74% 60%;
    --primary-foreground: 0 0% 98%;

    /* Secondary: Slightly darker version of #F7D317 for dark mode */
    --secondary: 49 80% 45%;
    --secondary-foreground: 0 0% 9%;

    --muted: 267 30% 20%;
    --muted-foreground: 0 0% 63.9%;

    /* Accent using secondary color */
    --accent: 49 80% 45%;
    --accent-foreground: 0 0% 9%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 267 30% 20%;
    --input: 267 30% 20%;
    --ring: 267 74% 60%;
    --chart-1: 267 74% 60%;
    --chart-2: 49 80% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

  @layer base {
  * {
    @apply border-border;
    }
  body {
    @apply bg-background text-foreground;
    }
}